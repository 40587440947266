import AOS from 'aos';
import 'slick-carousel/slick/slick';

export default {
  init() {
    // JavaScript to be fired on all pages

    // init animate on scroll
    $(window).on('load', function () {
      AOS.refresh();
    });
    $(function () {
      AOS.init({
        duration: 500,
        easing: 'ease-out-quart',
        once: true,
      });
    });

    // Collapse ////////////////////////////////////////////////////////
    // Initialized collapse elements
    setTimeout(() => {
      $('.collapse').each(function () {
        var elm = $(this);

        if (
          elm.hasClass('show') &&
          elm.prev('.collapse-toggle').hasClass('active')
        ) {
          elm.show();
        }
      });
    }, 500);

    // When collapse toggle is clicked
    $('body').on('click', '.collapse-toggle', function () {
      var toggle = $(this);
      var group = toggle.data('group');
      var target = $(toggle.data('target'));
      var limit = toggle.data('limit');

      if (limit === 'any') {
        target.slideToggle(400);
        toggle.toggleClass('active');
      } else {
        if (toggle.hasClass('active')) {
          toggle.removeClass('active');
          target.slideUp(400);
        } else {
          $(group + '.collapse-toggle').removeClass('active');
          toggle.addClass('active');
          $(group + '.collapse').slideUp(400);
          target.slideDown(400);
        }
      }

      // var slickTarget = toggle.data('slick');
      // if (slickTarget !== undefined) {
      //   var slideIndex = toggle.data('goto');
      //   $(slickTarget).slick('slickGoTo', parseInt(slideIndex));
      // }

      var autoscroll = toggle.data('autoscroll');

      if (autoscroll) {
        setTimeout(() => {
          $('html,body').animate(
            {
              scrollTop: toggle.offset().top,
            },
            1000
          );
        }, 500);
      }
    });
    // Collapse //////////////////////////////////////////////////////// END

    // smooth scroll anchor links
    $(function () {
      $('a[href*=\\#]:not([href=\\#])').on('click', function () {
        if (
          location.pathname.replace(/^\//, '') ==
            this.pathname.replace(/^\//, '') &&
          location.hostname == this.hostname
        ) {
          var target = $(this.hash);
          target = target.length
            ? target
            : $('[name=' + this.hash.slice(1) + ']');
          if (target.length) {
            $('html,body').animate(
              {
                scrollTop: target.offset().top,
              },
              750
            );
            return false;
          }
        }
      });
    });

    // hero slider
    const heroSlider = $('.hero-slider__slides');
    let heroSliderAutoplay = heroSlider.data('autoplay');
    let heroSliderAutoplaySpeed = heroSlider.data('autoplay-speed');

    if (heroSliderAutoplay == undefined) {
      heroSliderAutoplay = false;
    }
    if (heroSliderAutoplaySpeed == undefined) {
      heroSliderAutoplaySpeed = 4000;
    }

    heroSlider.slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      arrows: true,
      prevArrow: '<button type="button" class="slick-prev"></button>',
      nextArrow: '<button type="button" class="slick-next"></button>',
      fade: true,
      cssEase: 'linear',
      autoplay: heroSliderAutoplay,
      autoplaySpeed: heroSliderAutoplaySpeed,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            arrows: false,
          },
        },
      ],
    });

    // animate on slide change
    setTimeout(() => {
      // remove all animated class except for first active slide
      heroSlider
        .find('.slick-slide:not(.slick-active) [data-aos]')
        .removeClass('aos-animate');
    }, 300);

    heroSlider.on('beforeChange', function (event, slick) {
      // console.log('Before slide ...');
      const slider = $(slick.$slides);
      slider.find('[data-aos]').removeClass('aos-animate');
    });
    heroSlider.on('afterChange', function (event, slick, currentSlide) {
      // console.log('After slide ...');
      const slider = $(slick.$slides);
      slider.find('[data-aos]').removeClass('aos-animate');
      var currentSlideDiv = $(slick.$slides.get(currentSlide));
      currentSlideDiv.find('[data-aos]').addClass('aos-animate');
    });

    // Overlay
    function overlayIsActive() {
      $('body').addClass('overlay-active');
    }
    function overlayIsInactive() {
      $('body').removeClass('overlay-active');
    }

    // Close post detail overlay
    $('.close-overlay, .overlay-wrapper').on('click', function (e) {
      e.stopPropagation();
      const $this = $(this);

      if ($this.hasClass('overlay-wrapper')) {
        $this.fadeOut(250, function () {
          overlayIsInactive();
        });
      } else {
        $this.closest('.overlay-wrapper').fadeOut(250, function () {
          overlayIsInactive();
        });
      }
    });
    $('.overlay-pointer-area').on('click', function (e) {
      e.stopPropagation();
    });

    // Image carousel
    $('.image-carousel').each(function () {
      const $this = $(this);
      let slider_autoplay = $this.data('autoplay');
      if (slider_autoplay == undefined) {
        slider_autoplay = false;
      }

      $this.slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        dots: false,
        arrows: true,
        prevArrow: '<button type="button" class="slick-prev"></button>',
        nextArrow: '<button type="button" class="slick-next"></button>',
        autoplay: slider_autoplay,
        autoplaySpeed: 3000,
        pauseOnHover: false,
        adaptiveHeight: true,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              arrows: false,
              dots: true,
            },
          },
        ],
      });
    });

    // load more news posts if available
    var news_page = 2;
    const loadmore_news_btn = $('#all-news-list .btn-loadmore');
    loadmore_news_btn.on('click', function () {
      const elm = $(this);
      const loader = elm.siblings('#loader');
      var data = {
        action: 'load_news_posts_by_ajax',
        page: news_page,
        security: window.ajax.security,
      };
      elm.addClass('btn-disabled');
      loader.css('visibility', 'visible');

      $.post(window.ajax.url, data, function (response) {
        if ($.trim(response) != '') {
          $('#all-news-list .news-list').append(response);
          news_page++;
        } else {
          elm.hide();
        }
        elm.removeClass('btn-disabled');
        loader.css('visibility', 'hidden');
      });
    });

    // live clock
    var apikey = 'AIzaSyBk-Tz7aFDGeQ9Ou2J8xer_6QFxKK4gCsA';
    var offsets = [];
    var xhr = [];
    var targetDate = new Date(); // Current date/time of user computer
    var timestamp =
      targetDate.getTime() / 1000 + targetDate.getTimezoneOffset() * 60; // Current UTC date/time expressed as seconds since midnight, January 1, 1970 UTC

    // Get timezone offset for each clock
    const clocks = $('.pf-offices__clock');
    clocks.each(function (index) {
      // Get city gps location from data attribute
      var lat = $(this).data('latitude');
      var long = $(this).data('longitude');
      var loc = lat + ', ' + long;
      var apicall =
        'https://maps.googleapis.com/maps/api/timezone/json?location=' +
        loc +
        '&timestamp=' +
        timestamp +
        '&key=' +
        apikey;

      xhr[index] = new XMLHttpRequest(); // create new XMLHttpRequest2 object
      xhr[index].open('GET', apicall); // open GET request
      xhr[index].onload = function () {
        if (xhr[index].status === 200) {
          // if Ajax request successful
          var output = JSON.parse(xhr[index].responseText); // convert returned JSON string to JSON object
          if (output.status == 'OK') {
            // if API reports everything was returned successfully
            offsets[index] = output.dstOffset * 1000 + output.rawOffset * 1000; // get DST and time zone offsets in milliseconds
          }
        } else {
          console.log(
            'Request failed.  Returned status of ' + xhr[index].status
          );
        }
      };
      xhr[index].send(); // send request
    });

    // setTimeout(runClock, 3000);
    setInterval(runClock, 100);

    // Initialize clocks
    function runClock() {
      clocks.each(function (index) {
        targetDate = new Date();
        timestamp =
          targetDate.getTime() / 1000 + targetDate.getTimezoneOffset() * 60;
        var localdate = new Date(timestamp * 1000 + offsets[index]);

        var seconds = localdate.getSeconds(),
          minutes = localdate.getMinutes(),
          hours = localdate.getHours();

        hours = ((hours > 11 ? hours - 12 : hours) / 12) * 60;

        var clockId = $(this).attr('id');
        document.querySelector(`#${clockId} .clock__hours`).dataset.value =
          hours;
        document.querySelector(`#${clockId} .clock__seconds`).dataset.value =
          seconds;
        document.querySelector(`#${clockId} .clock__minutes`).dataset.value =
          minutes;

        // setInterval(runClock, 100);
      });
    }

    // display team member bio on click
    $('.team-member__content.has-bio').on('click', function () {
      const elm = $(this);
      elm.toggleClass('active');
    });

    // client logo carousel
    $('.client-logo-carousel__list').each(function () {
      $(this).slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        prevArrow: '<button type="button" class="slick-prev"></button>',
        nextArrow: '<button type="button" class="slick-next"></button>',
        autoplay: true,
        autoplaySpeed: 2000,
      });
    });

    // Jobs - clients image slider
    $('#jobs-clients-slider').slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: '20%',
      dots: false,
      arrows: true,
      // autoplay: true,
      // autoplaySpeed: 3000,
      // pauseOnHover: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            arrows: false,
            dots: true,
            centerMode: false,
          },
        },
      ],
    });

    // Jobs - digital image slider
    $('#jobs-digital-slider').slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      dots: true,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 3000,
      pauseOnHover: true,
      // adaptiveHeight: true,
    });

    // Jobs - Contact team section
    // randomise each team member item position
    const boundary = $('#contact-team__list');
    if (boundary.length) {
      randomiseTeamMembers(boundary);
    }

    $(window).on('resize', function () {
      if (boundary.length) {
        randomiseTeamMembers(boundary);
      }
    });

    function randomiseTeamMembers(boundary) {
      const TRIES_PER_BOX = 100;
      const randUint = (range) => (Math.random() * range) | 0;

      let placing = [];
      $('.contact-team__member').each(function () {
        $(this).removeClass('placed');
        let element = $(this)[0];
        placing.push(Bounds(boundary, element, 10));
      });
      const fitted = [];
      const areaToFit = Bounds(boundary);
      let maxTries = TRIES_PER_BOX * placing.length;

      while (placing.length && maxTries > 0) {
        let i = 0;
        while (i < placing.length) {
          const box = placing[i];
          box.moveTo(
            randUint(areaToFit.w - box.w),
            randUint(areaToFit.h - box.h)
          );
          if (fitted.every((placed) => !placed.overlaps(box))) {
            fitted.push(placing.splice(i--, 1)[0].placeElement());
          } else {
            maxTries--;
          }
          i++;
        }
      }
    }

    function Bounds(boundary, el, pad = 0) {
      const containerWidth = boundary.innerWidth();
      const containerHeight = boundary.innerHeight();

      const box = el
        ? el.getBoundingClientRect()
        : {
            left: 0,
            top: 0,
            right: containerWidth,
            bottom: containerHeight,
            width: containerWidth,
            height: containerHeight,
          };

      return {
        l: box.left - pad,
        t: box.top - pad,
        r: box.right + pad,
        b: box.bottom + pad,
        w: box.width + pad * 2,
        h: box.height + pad * 2,
        overlaps(bounds) {
          return !(
            this.l > bounds.r ||
            this.r < bounds.l ||
            this.t > bounds.b ||
            this.b < bounds.t
          );
        },
        moveTo(x, y) {
          this.r = (this.l = x) + this.w;
          this.b = (this.t = y) + this.h;
          return this;
        },
        placeElement() {
          if (el) {
            el.style.top = this.t + pad + 'px';
            el.style.left = this.l + pad + 'px';
            el.classList.add('placed');
          }
          return this;
        },
      };
    }

    $('.contact-team__member').on('click', function () {
      const form_data = $(this).data('form');
      const contactTeamOverlay = $('#overlay-contact-team');

      if (form_data) {
        const { name, name_field_id, email, email_field_id, position, image } =
          form_data;
        contactTeamOverlay
          .find('.overlay-contact-image')
          .css('background-image', `url(${image})`);
        contactTeamOverlay.find('.contact-name').text(name);
        contactTeamOverlay.find('.contact-position').text(position);

        const contactForm = contactTeamOverlay.find('.gform_wrapper');
        const nameField = `.gform_hidden[name="input_${name_field_id}"]`;
        const emailField = `.gform_hidden[name="input_${email_field_id}"]`;

        contactForm.find(nameField).val(name);
        contactForm.find(emailField).val(email);
      }

      contactTeamOverlay.fadeIn(0, function () {
        overlayIsActive();
      });
    });

    $('#testimonials-list').slick({
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      dots: true,
      arrows: false,
      pauseOnHover: true,
      // autoplay: true,
      // autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });

    // end init
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired

    // mobile menu panel
    $('#mobile-menu-toggle').on('click', function () {
      $('#mobile-menu-panel').addClass('active');
    });

    $('#close-mobile-menu-panel').on('click', function () {
      $('#mobile-menu-panel').removeClass('active');
      setTimeout(() => {
        $('.nav-mobile .menu-item-has-children').removeClass('active');
        $('.nav-mobile .menu-item-has-children .sub-menu').addClass('close');
        $('.nav-mobile .menu-item-has-children .sub-menu').slideUp(400);
      }, 300);
    });

    // Initialise collapse for mobile nav menu
    var idx = 1;

    $('.nav-mobile .menu-item-has-children').each(function () {
      // console.log('each', 'processing...');
      var elm = $(this);

      // Setting up collapse
      elm.find('.sub-menu').attr('id', 'mobile-submenu-' + idx);
      elm.find('.sub-menu').addClass('mobile-submenu collapse close');
      elm.find('.sub-menu').slideUp(400);

      // Setting up toggle
      elm.attr('data-target', '#mobile-submenu-' + idx);
      elm.attr('data-group', '.mobile-submenu');
      elm.attr('data-limit', 'one');
      elm.addClass('collapse-toggle mobile-submenu');

      idx++;
    });

    // for gravity form styling
    function style_gravity_form() {
      const gf_wrapper = $('.gform_wrapper');

      // add custom label to file input
      const default_fileinput_label = 'Upload';
      gf_wrapper.find('.ginput_container_fileupload').each(function () {
        var elm = $(this);
        var input = elm.find('input[type=file]');

        if (elm.find('.gf-fileinput-button').length === 0) {
          elm.prepend(
            `<label class="gf-fileinput-button btn btn-outline" for="${input.attr(
              'id'
            )}">${default_fileinput_label}</label>`
          );
        }

        if (elm.find('.gf-fileinput-label').length === 0) {
          elm.prepend(
            `<label class="gf-fileinput-label" for="${input.attr(
              'id'
            )}"></label>`
          );
        }
      });

      // change label based on chosen file's name
      gf_wrapper
        .find('.ginput_container_fileupload input[type=file]')
        .on('change', function () {
          var elm = $(this);
          var fileinput_label = elm.siblings('.gf-fileinput-label');
          var filename = elm.val().split('\\').pop();
          fileinput_label.html(filename);

          if (!filename || filename.length === 0 || filename === '') {
            fileinput_label.html(default_fileinput_label);
          }
        });
    }

    style_gravity_form();
    $(document).on('gform_post_render', function () {
      style_gravity_form();
    });

    // control video
    const videoPlayer = $('.video-player');
    videoPlayer.each(function () {
      const elm = $(this);
      const videoElm = elm.find('video');
      const video = videoElm.get(0);
      const playControl = elm.find('.video-player__play-control');
      const volumeControl = elm.find('.video-player__volume-control');

      // console.log('video', video);
      // const vids = document.getElementsByTagName('video');

      if (video) {
        // On play, show pause button
        video.addEventListener('playing', function () {
          playControl.find('img').removeClass('active');
          playControl.find('.btn-pause').addClass('active');
        });

        // On pause, show play button
        video.addEventListener('pause', function () {
          playControl.find('img').removeClass('active');
          playControl.find('.btn-play').addClass('active');
        });

        // On end video, show play button
        video.addEventListener('ended', function () {
          playControl.find('img').removeClass('active');
          playControl.find('.btn-play').addClass('active');
        });

        // on volume change, toggle volume control icons
        video.addEventListener('volumechange', function () {
          if (video.muted) {
            volumeControl.find('img').removeClass('active');
            volumeControl.find('.btn-mute').addClass('active');
          } else {
            volumeControl.find('img').removeClass('active');
            volumeControl.find('.btn-unmute').addClass('active');
          }
        });

        playControl.on('click', function () {
          if (video.paused) {
            video.play();
          } else {
            video.pause();
          }
        });

        // toggle mute
        volumeControl.on('click', function () {
          if (video.muted) {
            video.muted = false;
          } else {
            video.muted = true;
          }
        });
      }
    });

    // Reload gravity form ////////////////////////////////////////////////////////
    // eslint-disable-next-line no-unused-vars
    function gwrf(args) {
      // prototype arguments, created when we instantiate it
      this.formId = args.formId;
      this.spinnerUrl = args.spinnerUrl;
      this.refreshTime = args.refreshTime;
      this.refreshTimeout = null;

      // form wrapper class followed by the form id
      this.formWrapper = $('#gform_wrapper_' + this.formId);
      this.staticElem = this.formWrapper.parent();

      // we want to make sure that we'll have the cloned form html
      this.formHtml = $('<div />').append(this.formWrapper.clone()).html();

      this.spinnerInitialized = false;

      this.init = function () {
        var gwrf = this;

        // this is Gravity Forms .js hook which we bind a function call to
        // once the AJAX confirmation was loaded we'll trigger the function
        $(document).on('gform_confirmation_loaded', function (event, formId) {
          // let's make sure we'll reload the right form
          if (formId != gwrf.formId || gwrf.refreshTime <= 0) return;

          // let's reload the form after some time
          gwrf.refreshTimeout = setTimeout(function () {
            gwrf.reloadForm();
          }, gwrf.refreshTime * 1000);
        });

        // let's make sure that the form will be closed only after the .closed-lightbox element was
        // clicked on. This might be a button in the top right corner of our popup
        $('.close-overlay, .overlay-wrapper').on('click', function (event) {
          event.preventDefault();
          gwrf.reloadForm();
        });
      };

      // heart of our functionality
      this.reloadForm = function () {
        // let's check if the confirmation message has already been created
        if (
          this.staticElem.find('.gform_confirmation_message_' + this.formId)
            .length
        ) {
          if (this.refreshTimeout) clearTimeout(this.refreshTimeout);

          // let's look for the confirmation element and get the HTML of the parent form
          this.staticElem
            .find('.gform_confirmation_message_' + this.formId)
            .wrap('<div />')
            .parent()
            .html(this.formHtml);
          // let's get rid of the HTML of the FORM
          this.staticElem.find('#gform_wrapper_' + this.formId).unwrap();

          // let's rerender the form
          $(document).trigger('gform_post_render', [this.formId, 0]);
          // if we had datepicker let's reinstantiate it
          // if (window['gformInitDatepicker']) gformInitDatepicker();
        }
      };

      // utility used to display the "spinner" loading image
      this.initSpinner = function () {
        var gwrf = this;

        this.staticElem.on('submit', '#gform_' + this.formId, function () {
          $('#gform_submit_button_' + gwrf.formId)
            .attr('disabled', true)
            .after(
              '<' +
                'img id="gform_ajax_spinner_' +
                gwrf.formId +
                '"  class="gform_ajax_spinner" src="' +
                gwrf.spinnerUrl +
                '" />'
            );
          gwrf.formWrapper
            .find('.gform_previous_button')
            .attr('disabled', true);
          gwrf.formWrapper
            .find('.gform_next_button')
            .attr('disabled', true)
            .after(
              '<' +
                'img id="gform_ajax_spinner_' +
                gwrf.formId +
                '"  class="gform_ajax_spinner" src="' +
                gwrf.spinnerUrl +
                '" alt="" />'
            );
        });
      };

      this.init();
    }
    // Reload gravity form //////////////////////////////////////////////////////// END
    $('.overlay-form').each(function () {
      let gf_form_id = $(this).data('form-id');

      if (
        gf_form_id !== undefined &&
        gf_form_id !== '' &&
        gf_form_id !== null
      ) {
        new gwrf({
          formId: gf_form_id,
          spinnerUrl: `${window.ajax.gf_plugin_url}/images/spinner.svg`,
          refreshTime: 0, // 0 for no refresh
        });
      }
    });

    // refresh AOS
    AOS.refresh();

    ////// end finalize
  },
};
